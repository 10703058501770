<template>
  <div>
    <div class="d-flex flex-row">
      <!-- <div style="height: inherit"> -->
      <!-- <section id="ecommerce-header"> -->
      <div class="col-sm-6 col-md-6 col-lg-3">
        <b-card>
          <!-- Multi Range -->
          <div class="">
            <b-input-group class="input-group-merge">
              <b-form-input placeholder="Search here" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
            <p class="font-weight-bold pt-2">
              Others
            </p>
            <div class="row justify-content-center">
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="overviews ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="overviews = !overviews"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="EyeIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Overview
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="budget ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="budget = !budget"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="BarChartIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Budget
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="location ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="location = !location"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="MapPinIcon"
                      size="2x"
                    />
                    <p class="card-text pt-0">
                      Location
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row justify-content-center">
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="weekTask ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="weekTask = !weekTask"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="FileTextIcon"
                      size="2x"
                    />
                    <p class="card-text pt-0">
                      Weekly Task
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="workHours ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="workHours = !workHours"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="ClockIcon"
                      size="2x"
                    />
                    <p class="card-text pt-0">
                      Working Hours
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="issues ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="issues = !issues"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="LoaderIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Issues
                    </p>
                  </div>
                </div>
                <!-- <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="status ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="status = !status"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Status
                    </p>
                  </div>
                </div> -->
              </div>
              <div class="col-md-5 col-sm-12" />
              <!-- <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="manpower ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="manpower = !manpower"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="ShieldIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Manpower
                    </p>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="issues ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="issues = !issues"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="LoaderIcon"
                      size="2x"
                    />

                    <p class="card-text pt-0">
                      Issues
                    </p>
                  </div>
                </div>
              </div> -->

            </div>
            <p class="font-weight-bold pt-2">
              Tables
            </p>
            <div class="row justify-content-center">
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="projTeam ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="projTeam = !projTeam"
                >
                  <div class="card-body">
                    <feather-icon
                      icon="UserIcon"
                      size="2x"
                    />
                    <p class="card-text pt-0">
                      Project Teams
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div
                  class="text-center mb-2 border border-success"
                  style="width: 8rem; height: 7rem; cursor: pointer;"
                  :style="membTeam ? 'background-color: rgba(0, 174, 157, 0.1)' : ''"
                  @click="membTeam = !membTeam"
                >
                  <div class="card-body">

                    <feather-icon
                      icon="UsersIcon"
                      size="2x"
                    />
                    <p class="card-text pt-0">
                      Team Members
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="row col-9">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="d-flex justify-content-between">
              <div class="mr-auto my-2">
                15 Widgets
              </div>
              <div class="d-flex p-2">
                <!-- <div class="mr-1">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                  >
                    Discard
                  </button>
                </div> -->
                <div class="">
                  <router-link :to="{name: 'analytic'}">
                    <div
                      class="btn btn-primary"
                      style="width: 130px"
                      @click="saveEdit"
                    >
                      Save
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div
                v-for="(item, index) in overview.type"
                v-show="overviews"
                :key="index"
                class="col-sm-12 col-md p-0 mb-1"
              > <goal-overview
                style="height: 370px"
                :data="item"
                class="onHover cursorIcon"
                :class="index===0? '' : 'ml-1'"
              />
              </div>
            </div>

            <!-- <div
              v-show="location"
              class="row m-0 p-0 justify-content-between col-12 mb-2"
            >
              <div class="col-8 p-0 pr-1">
                 {{ currentLocation }}
                <map-location
                  :on-site-location="onSiteLocation"
                  class="m-0 p-0 mr-1"
                  style="height: 100%"
                />

              </div>
              <on-site-location

                :locations="onSiteLocation"
                style="height: 100%"
                class="m-0 p-0 col-4"
                @newLocation="(selectedLocation) => {currentLocation = selectedLocation}"
              />

            </div> -->
            <div class="row justify-content-between mb-2">
              <div class="p-0 pr-1 col-md-5 col-sm-12 col-sm-12 col-md-5 ">
                <weekly-task
                  v-show="weekTask"
                  class="p-0  m-0"
                />
              </div>
              <card-analytic-revenue
                v-show="workHours"
                id="hide"
                class="p-0 m-0 col-7 "
              />
            </div>
            <div class="row justify-content-between">
              <!-- <div
                v-show="status"
                class="col-md-5 col-sm-12 row align-items-between"
              >
                <total-status-chart
                  :data="totalStatus[0]"
                  class="col-12 m-0 mb-1"
                />
                <total-status-chart
                  :data="totalStatus[1]"
                  class="col-12 m-0"
                />
              </div>

              <man-power-tracker
                v-show="manpower"
                class="col-7 p-0 m-0"
              /> -->

              <div class="row col-12 m-0 p-0 justify-content-between mt-2 mb-2">
                <div class="col-md-5 col-sm-12 p-0 pr-1">
                  <non-compliance-issues
                    v-show="issues"
                    class=""
                    style="height: 100%"
                  />
                </div>
                <budget-bar-chart
                  v-show="budget"
                  class="p-0 m-0 col-7"
                />

              </div>

              <!-- <div class="row m-0 p-0 col-12 mb-5">
                <project-team
                  v-show="projTeam"
                  class="col-12"
                />
                <project-member
                  v-show="membTeam"
                  class="col-12 mt-3"
                />

              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- </section> -->
      <!-- Overlay -->
      <!-- <div class="body-content-overlay" /> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {
  BCard, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import GoalOverview from './GoalOverview.vue'
// import TotalStatusChart from './TotalStatusChart.vue'
// import OnSiteLocation from './OnSiteLocation.vue'
// import ProjectTeam from './ProjectTeam.vue'
// import ProjectMember from './ProjectMember.vue'
// import ManPowerTracker from './ManPowerTracker.vue'
import BudgetBarChart from './BudgetBarChart.vue'
import WeeklyTask from './WeeklyTask.vue'
import CardAnalyticRevenue from './CardAnalyticRevenue.vue'
import NonComplianceIssues from './NonComplianceIssues.vue'
// import MapLocation from './mapLocation.vue'
// import { useMediaFiltersSortingAndPagination, useMediaUi, useVideoRemoteData } from './useMediaGalleryDeep'

export default {
  directives: {
    Ripple,
  },
  components: {
    // SFC
    BCard,
    // ManPowerTracker,
    BudgetBarChart,
    WeeklyTask,
    CardAnalyticRevenue,
    NonComplianceIssues,
    // MapLocation,
    // OnSiteLocation,
    // ProjectTeam,
    // ProjectMember,
    // TotalStatusChart,
    GoalOverview,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      overviews: true,
      // location: true,
      budget: true,
      // manpower: true,
      // status: true,
      issues: true,
      weekTask: true,
      workHours: true,
      projTeam: true,
      membTeam: true,
      overview: {},
      totalStatus: {},
      projectTeam: [],
      // onSiteLocation: [],
    }
  },
  created() {
    this.$http.get('/analytics/data')
      .then(response => {
        const dashboardData = response.data
        this.overview = dashboardData.dataOverview
        this.totalStatus = dashboardData.totalStatus.status
        this.projectTeam = dashboardData.listProjectTeam.projectTeam
        // this.onSiteLocation = dashboardData.onsiteLocationList.location
      })

    const tempConfig = localStorage.getItem('config')
    if (tempConfig !== null) {
      const config = JSON.parse(tempConfig)
      this.overviews = config.overviews
      // this.location = config.location
      this.budget = config.budget
      // this.manpower = config.manpower
      // this.status = config.status
      this.issues = config.issues
      this.weekTask = config.weekTask
      this.workHours = config.workHours
      this.projTeam = config.projTeam
      this.membTeam = config.membTeam
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    saveEdit() {
      console.log('saveeee')
      const config = {
        overviews: this.overviews,
        // location: this.location,
        sensorDevices: false,
        budget: this.budget,
        manpower: this.manpower,
        status: this.status,
        issues: this.issues,
        weekTask: this.weekTask,
        workHours: this.workHours,
        projTeam: this.projTeam,
        membTeam: this.membTeam,
      }

      localStorage.setItem('config', JSON.stringify(config))
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
